import { useEffect } from "react";
import { useRouter } from "next/router";
import { getParamsIdx } from "helpers/urls";

export default function Custom404() {
  const router = useRouter();
  const params = router.asPath.slice(getParamsIdx(router.asPath));

  useEffect(() => {
    router.replace(`/${params}`);
  });

  return null;
}
